<template>
  <div>
    <router-view />
    <div class="dome" v-if="show">

  <img v-lazy="src" alt="" class="icon" @load="getImg">
    </div>
  </div>
</template>
<script> 

export default {
 
  data() {
    return {
     show:false,
     yingl:true,
     src: './assets/gg(2).gif'
    } 
},


mounted(){
  // this.$router.go(0);
//   let fhfh = 'http://www.jjyyxh.cn/concerning/content'
//  console.log( (fhfh.split('.cn/')[0] + '.cn/index.php'))
  // localStorage.setItem("ss", window.location.href + 'index.php/');
   this.timeupdate()
},
methods: {
 getImg(){
// this.show = false
 },
timeupdate(e){
  
  // console.log(e.timeStamp)
  // // if(e.timeStamp >= 41714.40499997698){
  // //    this.show = false
  // // }

  
}
},
watch: {
   
  }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
.dome {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; 
  background-color: rgba(70, 69, 69, 0.8);
  position: fixed;
  z-index: 999;
}
.video-js{
  width: 100%;
  height: 100%; 
}
.icon{
  display: block;
  margin: 0 auto;
}
</style>