import Vue from 'vue'
import App from './App.vue'

import {Swipe,SwipeItem} from 'vant';
Vue.use(Swipe);
Vue.use(SwipeItem);
import 'vant/lib/index.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false
Vue.use(ElementUI);
import axios from 'axios'
Vue.prototype.$axios = axios
import router from './router'
import store from './store'
// Vue.prototype.ss = '/api/index.php/';
Vue.prototype.ss = window.location.href.split('.cn/')[0] + '.cn/index.php/';
import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
  loading: require("./assets/gg(2).gif"), //加载成功图片
   try:1 //加载图片数量
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
