import Vue from 'vue'
import VueRouter from 'vue-router'
import hh from '../views/hh.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: hh
  },
  {
    path: '/secondary',
    name: 'secondary', 
    component: () => import(/* webpackChunkName: "about" */ '../views/secondary.vue'),
    children:[
      {
        path: '/page',
        name: 'page', 
        component: () => import(/* webpackChunkName: "about" */ '../views/page.vue'),
        meta: { title: '首页文章' },
        children: [
          {
            path: 'pagelist',
            name:'pagelist',
            component: () => import(/* webpackChunkName: "about" */ '../views/pagelist.vue'),
            meta: { title: '文章列表' }
          },
          {
            path: 'pagedet',
            name:'pagedet',
            component: () => import(/* webpackChunkName: "about" */ '../views/pagedet.vue'),
            meta: { title: '文章正文' }
          },
        ],
      },
      {
        path: '/about',
        name: 'about', 
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
        meta: { title: '信息中心' },
        children: [
          {
            path: 'profile',
            name:'profile',
            component: () => import(/* webpackChunkName: "about" */ '../views/profile.vue'),
            meta: { title: '交易平台公告' }
          },
          {
            path: 'text',
            name:'text',
            component: () => import(/* webpackChunkName: "about" */ '../views/text.vue'),
            meta: { title: '公告正文' }
          },
        ],
      },
      {
        path: '/law',
        name: 'law',
        component:() => import(/* webpackChunkName: "about" */ '../views/law.vue'),
        meta: { title: '交易规则' },
        children: [
          {
            path: 'regulations',
            name:'regulations',
            component: () => import(/* webpackChunkName: "about" */ '../views/regulations.vue'),
            meta: { title: '交易中心规则' }
          },
          {
            path: 'Legal',
            name:'Legal',
            component: () => import(/* webpackChunkName: "about" */ '../views/Legal.vue'),
            meta: { title: '交易中心规则' }
          },
        ],
      },
      {
        path: '/concerning',
        name: 'concerning',
        component:() => import(/* webpackChunkName: "about" */ '../views/concerning.vue'),
        meta: { title: '关于我们' },
        children: [
          {
            path: 'concer',
            name:'concer',
            component: () => import(/* webpackChunkName: "about" */ '../views/concer.vue'),
            meta: { title: '内容详情' }
          },
          {
            path: 'content',
            name:'content',
            component: () => import(/* webpackChunkName: "about" */ '../views/content.vue'),
            meta: { title: '文章列表' }
          },
          {
            path: 'ppt',
            name:'ppt',
            component: () => import(/* webpackChunkName: "about" */ '../views/ppt.vue'),
            meta: { title: '文件' }
          },
        ],
      },
      {
        path: '/commodity',
        name: 'commodity', 
        component: () => import(/* webpackChunkName: "about" */ '../views/commodity.vue'),
        meta: { title: '上架商品' },
        children: [
          {
            path: 'classification',
            name:'classification',
            component: () => import(/* webpackChunkName: "about" */ '../views/classification.vue'),
            meta: { title: '商品分类' }
          },
          {
            path: 'details',
            name:'details',
            component: () => import(/* webpackChunkName: "about" */ '../views/details.vue'),
            meta: { title: '商品详情' }
          },
        ],
      },
    ]
  },
  // {
  //   path: '/about',
  //   name: 'about', 
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  //   meta: { title: '新闻公告' },
  //   children: [
  //     {
  //       path: 'profile',
  //       name:'profile',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/profile.vue'),
  //       meta: { title: '交易中心公告' }
  //     },
  //     {
  //       path: 'text',
  //       name:'text',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/text.vue'),
  //       meta: { title: '公告正文' }
  //     },
  //   ],
  // },
  // {
  //   path: '/law',
  //   name: 'law',
  //   component:() => import(/* webpackChunkName: "about" */ '../views/law.vue'),
  //   meta: { title: '法律法规' },
  //   children: [
  //     {
  //       path: 'regulations',
  //       name:'regulations',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/regulations.vue'),
  //       meta: { title: '交易中心规则' }
  //     },
  //     {
  //       path: 'Legal',
  //       name:'Legal',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/Legal.vue'),
  //       meta: { title: '交易中心规则' }
  //     },
  //   ],
  // },
  // {
  //   path: '/concerning',
  //   name: 'concerning',
  //   component:() => import(/* webpackChunkName: "about" */ '../views/concerning.vue'),
  //   meta: { title: '关于我们' },
  //   children: [
  //     {
  //       path: 'concer',
  //       name:'concer',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/concer.vue'),
  //       meta: { title: '内容详情' }
  //     },
  //     {
  //       path: 'content',
  //       name:'content',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/content.vue'),
  //       meta: { title: '文章列表' }
  //     },
  //   ],
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
