<template>
  <div>
    <div class="top">
      <div class="cont">
        <img src="../assets/ziyuan1@2x.png" alt="" class="logo" />
        <!-- <div class="title">
          <router-link to="/" class="text">首页</router-link>
           <router-link to="/about/profile"><p class="text">新闻公告</p></router-link>
          <p class="text">产品</p>
          <p class="text">数据</p>
          <p class="text">服务</p>
           <router-link to="/law/regulations"><p class="text">法律法规</p></router-link>
         
         <router-link to="/concerning/content"> <p class="text">关于</p></router-link>
        </div> -->
      </div>
    </div>
    <!-- <router-view/> -->
    <el-carousel class="my-swipe" arrow="never" :interval="timenum">
      <el-carousel-item
        class="swipe"
        v-for="(item, index) in imglist"
        :key="index"
      >
        <img
          :src="`http://news.jjyyxh.cn/` + item.img"
          alt=""
          class="banner_img"
        />
      </el-carousel-item>
      <!-- <el-carousel-item class="swipe">
        <img :src="item.img" alt="" class="banner_img" />
        <img src="../assets/2.jpg" alt="" class="banner_img" />
      </el-carousel-item> -->
    </el-carousel>
    <div class="Navigation">
      <div
        class="Nav_box"
        @mouseover="mouseOver"
        @mouseleave="mouseLeave"
        :style="active"
      >
        <p :style="active_text">首页</p>
      </div>

      <div
        class="Nav_box"
        @mouseover="mouseOver1"
        @mouseleave="mouseLeave1"
        :style="active1"
      >
        <p :style="active_text1">{{ title1.name }}</p>
        <div class="hover_box" v-show="show1" @mouseleave="show1 = false">
          <router-link
            :to="{ path: 'commodity/classification' }"
            v-for="(item, index) in title1.child"
            :key="index"
          >
            <div
              class="hov_box"
              @mouseover="num = index"
              @mouseleave="num = -1"
              :class="[num == index ? 'kuai2' : '']"
            >
              {{ item.name }}
            </div>
          </router-link>
        </div>
      </div>

      <div
        class="Nav_box"
        @mouseover="mouseOver2"
        @mouseleave="mouseLeave2"
        :style="active2"
      >
        <p :style="active_text2">{{ title2.name }}</p>
        <div class="hover_box" v-show="show2" @mouseleave="show2 = false">
          <router-link
            :to="{ path: '/law/regulations', query: { id: item.id } }"
            v-for="(item, index) in title2.child"
            :key="index"
          >
            <div
              class="hov_box"
              @mouseover="num = index"
              @mouseleave="num = -1"
              :class="[num == index ? 'kuai2' : '']"
            >
              {{ item.name }}
            </div>
          </router-link>
        </div>
      </div>

      <div
        class="Nav_box"
        @mouseover="mouseOver3"
        @mouseleave="mouseLeave3"
        :style="active3"
      >
        <p :style="active_text3">{{ title3.name }}</p>
        <div class="hover_box" v-show="show3" @mouseleave="show3 = false">
          <router-link
            :to="{ path: 'about/profile' }"
            v-for="(item, index) in title3.child"
            :key="index"
          >
            <div
              class="hov_box"
              @mouseover="num = index"
              @mouseleave="num = -1"
              :class="[num == index ? 'kuai2' : '']"
            >
              {{ item.name }}
            </div>
          </router-link>
        </div>
      </div>

      <div
        class="Nav_box"
        @mouseover="mouseOver4"
        @mouseleave="mouseLeave4"
        :style="active4"
      >
        <p :style="active_text4">{{ title4.name }}</p>
        <div class="hover_box" v-show="show4" @mouseleave="show4 = false">
          <router-link
            :to="{ path: 'concerning/concer', query: { id: item.id } }"
            v-for="(item, index) in title4.child"
            :key="index"
          >
            <div
              class="hov_box"
              @mouseover="num = index"
              @mouseleave="num = -1"
              :class="[num == index ? 'kuai2' : '']"
            >
              {{ item.name }}
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="bankuai">
      <div class="bk_box1" style="position: relative;">
        <div class="box2_top">
          <div style="display: flex">
            <img src="../assets/Group3@2x.png" alt="" class="box2_icon" />
            <p class="box2_text">商品资讯<span class="box1_text"></span></p>
          </div>
        </div>
        <!-- <div class="box2_top" style="height: 30px">
          <div style="display: flex; align-items: center">
            <p class="xialatext">商品名称</p>
            <div style="margin-left: 10px">
              <el-select v-model="value" placeholder="请选择" size="small">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div> -->
        <!-- <div class="kxainimg" ref="kxian"></div> -->
        <!-- <qiun-vue-ucharts
          type="line"
          :opts="opts"
          :chartData="chartData"
          @getIndex="getIndex"
        /> -->
        
        <el-carousel class="spswipe" arrow="never" :interval="timenum" @change="gaibian">
          <el-carousel-item
            class="spswipe_box"
            v-for="(item, index) in list"
            :key="index"
            v-if="index<7"
          >
            <img
              :src="`http://news.jjyyxh.cn/` + item.thumb"
              alt=""
              class="swipe_img"
            />
            
          </el-carousel-item>
          <!-- <el-carousel-item class="spswipe_box" v-for="(item,index) in 6" :key="index">
              
             <h1>{{ index }}</h1>
         </el-carousel-item> -->
        </el-carousel>
        <div class="name_box">
          <router-link
            :to="{
              path: '/commodity/details',
              query: { id: item.id, uid: '5' },
            }"
           v-for="(item, index) in list"
            :key="index"  v-if="nameindex == index"
          >
          <p class="name_text" >{{ item.title }}</p>
          </router-link>
         
        </div>
      </div>

      <div class="bk_box2">
        <div class="box2_top">
          <div style="display: flex">
            <img src="../assets/Vector@2x(4).png" alt="" class="box2_icon" />
            <p class="box2_text">{{ dataname2 }}</p>
          </div>
          <router-link :to="{ path: '/about/profile' }">
            <p class="box2_text">更多></p>
          </router-link>
        </div>
        <div class="box2_cont">
          <router-link
            :to="{ path: '/about/text', query: { id: item.id, uid: '6' } }"
            v-for="(item, index) in list1"
            :key="index"
          >
            <p class="cont_text">
              {{ item.title }}
            </p>
          </router-link>
        </div>
      </div>
      <div class="bk_box2">
        <div class="box2_top">
          <div style="display: flex">
            <img src="../assets/Group3@2x.png" alt="" class="box2_icon" />
            <p class="box2_text">交易平台规则</p>
          </div>
          <router-link :to="{ path: '/law/regulations' }">
            <p class="box2_text">更多></p>
          </router-link>
        </div>
        <div class="box2_cont">
          <router-link
            :to="{
              path: 'law/Legal',
              query: { id: item.id, uid: '5' },
            }"
            v-for="(item, index) in list5"
            :key="index"
          >
            <p class="cont_text">
              {{ item.title }}
            </p>
          </router-link>
        </div>
      </div>
    </div>
    <div class="bankuai2">
      <div
        style="
          width: 1200px;
          height: 363px;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
          display: flex;
          margin: 0 auto;
          justify-content: space-between;
        "
      >
        <div class="box3">
          <div class="box3_top">
            <div class="box3_title">
              <div
                style="
                  height: 100%;
                  display: flex;
                  align-items: center;
                  padding-left: 17px;
                  padding-right: 17px;
                  position: relative;
                  cursor: pointer;
                "
                @click="cont = 0"
              >
                <img src="../assets/Group21@2x.png" alt="" class="box3_icon" />
                <p class="box3_text">{{ dataname }}</p>
                <div class="xian" v-if="cont == 0"></div>
              </div>
              <div
                style="
                  height: 100%;
                  display: flex;
                  align-items: center;
                  padding-left: 17px;
                  padding-right: 17px;
                  position: relative;
                  cursor: pointer;
                "
                @click="cont = 1"
              >
                <img src="../assets/Group20@2x.png" alt="" class="box3_icon" />
                <p class="box3_text">{{ dataname1 }}</p>
                <div class="xian" v-if="cont == 1"></div>
              </div>
            </div>
            <router-link
              :to="{ path: '/page/pagelist', query: { uid: '52' } }"
              v-if="cont == 0"
            >
              <!-- <p class="box2_text">更多></p> -->
            </router-link>
            <router-link
              :to="{ path: '/page/pagelist', query: { uid: '54' } }"
              v-if="cont == 1"
            >
              <!-- <p class="box2_text">更多></p> -->
            </router-link>
          </div>
          <div class="box3_cont" v-if="cont == 0">
            <!-- <div class="cont3" v-if="list2fist.thumb">
              <img :src="`http://news.jjyyxh.cn/`+ list2fist.thumb" alt="" class="cont3_icon" />
              <p class="cont3_text">{{list2fist.title}}</p>
            </div> -->
            <div class="cont4">
              <router-link
                :to="{ path: '/about/text', query: { id: item.id, uid: '52' } }"
                v-for="(item, index) in list2"
                :key="index"
              >
                <div class="cont4_row">
                  <p class="cont4_text1">
                    {{ item.title }}
                  </p>
                  <p class="cont4_text">{{ item.addTime | formatDate }}</p>
                </div>
              </router-link>
            </div>
          </div>
          <div class="box3_cont" v-if="cont == 1">
            <!-- <div class="cont3" v-if=" list3fist.thumb">
              <img :src="`http://news.jjyyxh.cn/`+ list3fist.thumb" alt="" class="cont3_icon" />
              <p class="cont3_text">{{list3fist.title}}</p>
            </div> -->
            <div class="cont4">
              <router-link
                :to="{ path: '/about/text', query: { id: item.id, uid: '54' } }"
                v-for="(item, index) in list3"
                :key="index"
              >
                <div class="cont4_row">
                  <p class="cont4_text1">
                    {{ item.title }}
                  </p>
                  <p class="cont4_text">{{ item.addTime | formatDate }}</p>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="bk_box3">
          <div class="box2_top">
            <div style="display: flex">
              <img src="../assets/Group3@2x.png" alt="" class="box2_icon" />
              <p class="box2_text">联系方式</p>
            </div>
            <router-link :to="{ path: '/page/pagelist', query: { uid: '57' } }">
              <!-- <p class="box2_text">更多></p> -->
            </router-link>
          </div>
          <div class="cont5">
            <Editor
              style=""
              v-model="list4.content"
              :defaultConfig="editorConfig"
              :mode="mode"
            />
            <!-- <router-link
              :to="{ path: '/page/pagedet', query: { id: item.id, uid: '57' } }"
              v-for="(item, index) in list4"
              :key="index"
            >
              <div class="cont4_row">
                <p class="cont4_text1">
                  {{ item.title }}
                </p>
                <p class="cont4_text">{{ item.addTime | formatDate }}</p>
              </div>
            </router-link> -->
          </div>
        </div>
      </div>
    </div>

    <div class="sttop">
      <img src="../assets/Vector@2x(5).png" alt="" class="sticon" />
      <p class="sttext">平台经济生态圈</p>
    </div>
    <!-- <div class="stimg" ref="chart"></div> -->
    <div class="stimg" @click="tupian">
      <img src="../assets/guanxi.png" alt="" class="tupian" />
    </div>

    <div class="fangda" v-if="tupianshow">
      <div class="fabox">
        <img src="../assets/guanxi.png" alt="" class="tupian" />
      </div>
      <img src="../assets/xx@2x.png" alt="" class="xxicon" @click="guanbi" />
    </div>
    <img src="../assets/piaochuang1.jpg" alt="" class="piao" />

    <div class="bottom">
      <img src="../assets/ziyuan1@2x.png" alt="" class="bottom_logo" />
      <div class="bottom_text">
        地址：广安市枣山园区物流大道东段400号8幢301号 邮编：638000
        联系电话：4008602808
      </div>
      <div class="bottom_text">
        广安久远云昌商品现货交易科技有限公司版权所有 蜀ICP备2022009318号-1
      </div>
      <div class="bottom_text">
        <a href="https://beian.miit.gov.cn" style="color: #000000"
          >蜀ICP备案编号：蜀ICP备2022009318号-1</a
        >
        <img src="../assets/20231107143406.png" alt="" class="trupian" />
        <a
          href="https://beian.mps.gov.cn/#/query/webSearch?code=51160002511600"
          rel="noreferrer"
          target="_blank"
          style="color: #000000"
          >川公网安备 51160002511600号</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import * as echarts from "echarts";
import qiunVueUcharts from "@qiun/vue-ucharts";

export default {
  name: "app",
  components: {
    qiunVueUcharts,
    Editor,
    Toolbar,
  },

  data() {
    return {
      editorConfig: { placeholder: "", readOnly: true },
      mode: "default", // or 'simple'
      imglist: [],
      timenum: 5000,
      active: "",
      active_text: "",
      active1: "",
      active_text1: "",
      active2: "",
      active_text2: "",
      active3: "",
      active_text3: "",
      active4: "",
      active_text4: "",
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      list: [],
      list1: [],
      list2: [],
      list3: [],
      list4: {},
      list5: [],
      datalist: [],
      dataname: "",
      dataname1: "",
      dataname2: "",
      list2fist: {},
      list3fist: {},
      num: -1,
      chart: "",
      kxian: "",
      cont: 0,
      tupianshow: false,
      title1: {},
      title2: {},
      title3: {},
      title4: {},
      title: [],
      nameindex:0,
      chartData: { categories: [], series: [] },
      //这里的 opts 是图表类型 type="line" 的全部配置参数，您可以将此配置复制到 config-ucharts.js 文件中下标为 ['line'] 的节点中来覆盖全局默认参数。实际应用过程中 opts 只需传入与全局默认参数中不一致的【某一个属性】即可实现同类型的图表显示不同的样式，达到页面简洁的需求。
      opts: {
        color: [
          "#1890FF",
          "#91CB74",
          "#FAC858",
          "#EE6666",
          "#73C0DE",
          "#3CA272",
          "#FC8452",
          "#9A60B4",
          "#ea7ccc",
        ],
        padding: [15, 15, 0, 5],
        legend: {},
        xAxis: {
          disableGrid: true,
        },
        yAxis: {
          data: [
            {
              min: 0,
            },
          ],
        },
        extra: {
          column: {
            type: "group",
            width: 30,
            activeBgColor: "#000000",
            activeBgOpacity: 0.08,
          },
        },
      },
    };
  },
  watch: {},
  mounted() {
    // this.initChart();
    // this.kxiantu();
    // this.getServerData();
  },
  // beforcreated(){
  //   console.log('dddddddd')
  // },
  created() {
    console.log(this.ss);

    this.getlist();
    this.getdata();
    this.getdata1();
    this.getdata2();
    this.getdata3();
  },
  methods: {
    gaibian(e){
      console.log(e)
      this.nameindex = e
    },



    getIndex(e) {
      console.log(e);
      if (e.legendIndex !== -1) {
        console.log("opoo");
        if (this.chartData.series.find((o) => o.name == "全部") == undefined) {
          this.chartData.series.push({
            name: "全部",
            data: [],
            show: false,
          });
        }
      }

      if (e.legendIndex == -1) {
        console.log("ddd");
      } else if (e.legendIndex == this.chartData.series.length - 1) {
        this.getlist();
      } else {
        this.chartData.series.forEach((ele, index) => {
          // ele.data = []
          if (index == e.legendIndex) {
            ele.show = true;
          } else {
            ele.show = false;
          }
        });
      }
    },

    getlist() {
      // this.list = [];
      this.$axios({
        method: "post",
        url: this.ss + "Apidata/index",
        data: {},
      })
        .then((res) => {
          console.log(res.data);
          this.chartData = res.data.data.chartData;
          this.title = res.data.data.classify;
          this.title1 = res.data.data.classify[0];

          this.title2 = res.data.data.classify[1];
          this.title3 = res.data.data.classify[2];
          this.title4 = res.data.data.classify[3];
          // this.list = res.data.data.content.content1;
          // this.list1 = res.data.data.content.content2;
          // this.list2 = res.data.data.content.content3;
          // if(res.data.data.content.content3[0]){
          //   this.list2fist = res.data.data.content.content3[0]
          // }
          // if(res.data.data.content.content4[0]){
          //   this.list3fist = res.data.data.content.content4[0]
          // }
          // // this.list3fist = res.data.data.content.content4[0]
          // this.list3 = res.data.data.content.content4;
          // this.list4 = res.data.data.content.content5;

          this.imglist = res.data.data.image;
        })
        .catch((error) => {
          console.log(error.data);
        });
    },
    getdata() {
      // this.list = [];
      this.$axios({
        method: "GET",
        url: this.ss + "Apidata/contentList",
        params: {
          classifyID: 1,
          page: 1,
          pageSize: 10,
        },
      })
        .then((res) => {
          console.log(res.data);
          this.list = res.data.data.list;
          console.log(this.list);
        })
        .catch((error) => {});
    },
    getdata1() {
      // this.list = [];
      this.$axios({
        method: "GET",
        url: this.ss + "Apidata/contentList",
        params: {
          classifyID: 3,
          page: 1,
          pageSize: 10,
        },
      })
        .then((res) => {
          console.log(res.data);
          // this.list = res.data.data.list
          this.datalist = res.data.data.classify;
          this.dataname = res.data.data.classify[0].name;
          this.dataname1 = res.data.data.classify[1].name;
          this.dataname2 = res.data.data.classify[2].name;
          res.data.data.list.forEach((ele) => {
            if (this.datalist[0].id == ele.classifyID) {
              this.list2.push(ele);
            }
            if (this.datalist[1].id == ele.classifyID) {
              this.list3.push(ele);
            }
            if (this.datalist[2].id == ele.classifyID) {
              this.list1.push(ele);
            }
          });
          console.log(this.list);
        })
        .catch((error) => {});
    },
    getdata2() {
      // this.list = [];
      this.$axios({
        method: "GET",
        url: this.ss + "Apidata/contentDetailed",
        params: {
          id: "118",
        },
      })
        .then((res) => {
          console.log(res.data);
          // this.list = res.data.data.list
          this.list4 = res.data.data.content;

          // console.log(this.list);
        })
        .catch((error) => {});
    },
    getdata3() {
      // this.list = [];
      this.$axios({
        method: "GET",
        url: this.ss + "Apidata/contentList",
        params: {
          classifyID: 2,
          page: 1,
          pageSize: 10,
        },
      })
        .then((res) => {
          console.log(res.data);
          // this.list = res.data.data.list
          this.list5 = res.data.data.list;

          // console.log(this.list);
        })
        .catch((error) => {});
    },
    handleCommand(command) {
      // this.$message('click on item ' + command);
      console.log(command);
    },
    tupian() {
      this.tupianshow = true;
    },
    guanbi() {
      this.tupianshow = false;
    },
    mouseOver() {
      this.active = "background-color: #ffffff";
      this.active_text = "color:#333333";
    },
    mouseLeave() {
      this.active = "";
      this.active_text = "";
    },
    mouseOver1() {
      this.active1 = "background-color: #ffffff";
      this.active_text1 = "color:#333333";
      this.show1 = true;
    },
    mouseLeave1() {
      this.active1 = "";
      this.active_text1 = "";
      this.show1 = false;
    },
    mouseOver2() {
      this.active2 = "background-color: #ffffff";
      this.active_text2 = "color:#333333";
      this.show2 = true;
    },
    mouseLeave2() {
      this.active2 = "";
      this.active_text2 = "";
      this.show2 = false;
    },
    mouseOver3() {
      this.active3 = "background-color: #ffffff";
      this.active_text3 = "color:#333333";
      this.show3 = true;
    },
    mouseLeave3() {
      this.active3 = "";
      this.active_text3 = "";
      this.show3 = false;
    },
    mouseOver4() {
      this.active4 = "background-color: #ffffff";
      this.active_text4 = "color:#333333";
      this.show4 = true;
    },
    mouseLeave4() {
      this.active4 = "";
      this.active_text4 = "";
      this.show4 = false;
    },
    // 初始化图表样式
    kxiantu() {
      this.kxian = echarts.init(this.$refs.kxian);
      this.kxian.setOption({
        xAxis: {
          type: "category",
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        legend: {
          show: true,
          top: 0,
          icon: "roundRect", //图例icon图标
          type: "scroll",
        },
        yAxis: {
          type: "value",
        },
        grid: {
          x: 40,
          y: 45,
          x2: 5,
          y2: 20,
          borderWidth: 1,
        },
        series: [
          {
            data: [450, 932, 901, 934, 1290, 1330, 1320],
            name: "发见附件请问请问日附件",
            type: "line",
            // smooth: true, true 是曲线，false 是折线
          },
        ],
      });
    },
  },
  filters: {
    formatDate: function (value) {
      //10位时间戳转换
      let date = new Date(parseInt(value) * 1000);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let minute = date.getMinutes();
      let second = date.getSeconds();
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      return y + "-" + m + "-" + d;
    },
  },
};
</script>



<style  scoped  >
.name_text{
font-family: Microsoft YaHei, Microsoft YaHei;
font-weight: 400;
font-size: 14px;
color: #fff;
line-height: 40px;
text-align: left;
margin-left: 10px;
white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
 .name_box{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color: rgba(7, 78, 82, 0.445);
  
  z-index: 99;
 }

    /deep/ .bk_box1 .el-carousel__indicators {
        left: unset;
        transform: unset;
        right: 2%;
        z-index: 999;
    }
    /deep/ .bk_box1 .el-carousel__button {
        width: 8px;
        height: 8px;
        border: none;
        border-radius: 50%;
        background-color: rgba(219, 253, 255, 0.87);
    }
    /deep/ .bk_box1 .is-active .el-carousel__button {
        background: #bee69e;
        width: 8px;
        height: 8px;
    }
 
    /deep/ .bk_box1 .el-carousel__container {
        height: 100%;
    }


.swipe_img {
  width: 400px;
  height: 270px;
}
.spswipe {
  width: 400px;
  height: 270px;
  overflow: hidden;
}
.spswipe_box {
  width: 400px;
  height: 270px;
}

.swipe {
  width: 1200px;
  height: 460px;
}
.my-swipe {
  width: 1200px;
  height: 460px;
  margin: 10px auto 0;
  background-color: #438efa;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  text-align: center;
  margin: 0;
}
.banner_img {
  width: 1200px;
  height: 460px;
}
.router-link-active {
  text-decoration: none;
  color: #fff;
}
a {
  text-decoration: none;
  color: #fff;
}
.top {
  width: 100%;
  height: 100px;
}
.cont {
  width: 1200px;
  height: 100px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  width: 641px;
  height: 90px;
}
.bottom_logo {
  width: 428px;
  height: 60px;
  display: block;
  margin: 30px auto;
}
.title {
  width: 820px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.text {
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #0f1628;
  cursor: pointer;
}
.text:hover {
  color: #438efa;
}
.bottom {
  width: 100%;
  min-width: 1200px;
  height: 212px;
  background: #f6f6f6;
  overflow: hidden;
  margin-top: 40px;
}
.bottom_text {
  width: 100%;
  text-align: center;
  line-height: 25px;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Navigation {
  width: 1200px;
  height: 50px;
  background: linear-gradient(
    180deg,
    #438efa 0%,
    rgba(67, 142, 250, 0.9) 53%,
    #438efa 100%
  );
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  margin: 0 auto;
}
.Nav_box {
  position: relative;
  width: 240px;
  height: 100%;
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 50px;
  text-align: center;
  z-index: 999;
}
.hover_box {
  position: absolutes;
  width: 120px;
  color: #333333;
  /* background-color: #95c3f7c9; */

  z-index: 9999;
  /* border: 1px solid #438efa;
  border-bottom: none; */
  margin: 0 auto;
}
.hov_box {
  width: 120px;
  height: 30px;
  /* background: #95c3f7c9; */
  background-color: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  /* border-bottom: 1px solid #438efa; */
  /* color: #333333; */
  color: #438efa;
  font-size: 14px;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.kuai2 {
  background: #ddd8d8;
  color: #fff;
}
.bankuai {
  width: 1200px;
  min-height: 341px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
}
.bk_box1 {
  height: 100%;
  width: 400px;
}
.bk_box2 {
  width: 360px;
}
.box2_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 51px;
}
.box2_icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.box2_text {
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.box2_cont {
  width: 360px;
  height: 270px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #999999;
  box-sizing: border-box;
  overflow: hidden;
}
.cont_text {
  width: 338px;
  height: 18px;

  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin: 8px auto 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bankuai2 {
  width: 100%;
  min-height: 363px;
  background: #fff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.box3 {
  width: 388px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.box3_top {
  width: 100%;
  height: 44px;
  border-bottom: 2px solid #c8c8ca;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.trupian {
  width: 12px;
  margin-left: 15px;
  margin-right: 5px;
}
.box3_title {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
}
.box3_icon {
  width: 20px;
  height: 20px;
  margin-right: 1px;
}
.box3_cont {
  width: 100%;
  height: 244px;

  display: flex;
}
.cont3 {
  width: 260px;
  height: 100%;
  margin-left: 15px;
  margin-right: 30px;
}
.cont3_icon {
  width: 260px;
  height: 200px;
  margin-top: 15px;
}
.cont3_text {
  display: blocks;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #444444;
  text-align: center;
}
.cont4 {
  width: 388px;
  height: 100%;
  opacity: 1;
}
.cont4_row {
  width: 100%;
  height: 38px;
  border-bottom: 2px dashed #c8c8ca;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cont4_text1 {
  width: 280px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cont4_text {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.cont5_text {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.cont5 {
  width: 757px;
  height: 304px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #999999;
  overflow: hidden;
}
.cont5_row {
  padding-left: 20px;
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.cont5_text1 {
  width: 308px;
  height: 18px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box3_text {
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  margin-left: 10px;
}
.sttop {
  width: 1200px;
  height: 61px;
  opacity: 1;
  border-bottom: 2px solid #e3e3e5;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.sticon {
  width: 20px;
  height: 19px;
}
.sttext {
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  margin-left: 15px;
}
.stimg {
  width: 1200px;
  /* height: 800px; */
  opacity: 1;
  border: 2px dotted #e3e3e5;
  margin: 10px auto;
}
.box1_text {
  font-size: 10px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  margin-left: 10px;
}
.xialabox {
  border: 1px solid #438efa;
  margin-left: 10px;
  width: 100px;
  height: 20px;
  text-align: center;
  cursor: pointer;
}
.xialatext {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.kxainimg {
  width: 400px;
  min-height: 240px;
  /* position: relative;
  z-index: -1; */
}
.tupian {
  width: 100%;
}
.xian {
  width: 100%;
  height: 3px;
  border-radius: 5px;
  position: absolute;
  background-color: #438efa;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
}
.fangda {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(70, 69, 69, 0.8);
  z-index: 999;
}
.fabox {
  width: 75%;
  margin: 60px auto 0;
}
.xxicon {
  width: 50px;
  height: 50px;
  margin: 20px auto;
  display: block;
}
.piao {
  width: 100px;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 999;
  width: 200px;
  border-radius: 10px;
}
</style>
